<template>
  <div class="attachments-view">
    <div
      v-for="item in attachmentsList"
      :key="item.id"
      class="attachment"
      :style="maxH ? `max-height: ${maxH}${typeof maxH === 'number'?'px':''}` : ''">
      <div
        class="attachment-box relative w-full, h-full"
        :class="{
          'bg-black': !loadedAttachments[item.id],
          'mb-lg': attachmentsList.length > 1
          }">
        <vimeo-player
          ref="vimeo-player"
          v-if="item.type === 'embed/vimeo'"
          :value="item"
          :style="boxSizeStyle(item)"
          class="player-aspect-ratio"
          @paused="$emit('paused', $event)"
          @muted="$emit('muted', $event)"
          @timeupdate="$emit('timeupdate', $event)"
          @progress="$emit('progress', $event)"/>
        <div
          v-else-if="item.type === 'embed/mixcloud'"
          v-html="item.html"/>
        <div
          v-else-if="item.type === 'embed/html'"
          :style="boxSizeStyle(item)"
          class="embed-html-box">
          <div
            v-if="!loadedAttachments[item.id]"
            class="absolute flex inset-0 items-center justify-center">
            <span>Loading...</span>
          </div>
          <iframe
            :src="item.url"
            allowfullscreen
            :class="{loaded: loadedAttachments[item.id]}"
            @load="setLoaded(item.id)"/>
        </div>
        <img
          v-else
          :src="item.url"
          :alt="item.caption"
          :width="(item.dimensions || {}).w"
          :height="(item.dimensions || {}).h"
          :class="{
            outline: item.type === 'application/pdf',
            loaded: loadedAttachments[item.id],
            'bg-gray-400': !loadedAttachments[item.id]
          }"
          @load="setLoaded(item.id)"/>
        <a
          v-if="item.type === 'application/pdf'"
          class="button compact absolute top-0 right-0 mt-sm mr-sm flex flex-col items-center justify-center hover:no-underline focus:no-underline bg-milk"
          :href="item.original.url"
          target="_blank" download>
          <i class="material-icons">get_app</i><span class="text-xs">PDF</span>
        </a>
        <expandable-text-line v-if="item.caption && collapseCaption" class="caption text-xs">
          {{item.caption}}
        </expandable-text-line>
      </div>
      <div v-if="item.caption && !collapseCaption" class="text-xs pt-sm -mt-lg mb-lg">
        {{item.caption}}
      </div>
    </div>
  </div>
</template>

<script>
import VimeoPlayer from './VimeoPlayer'
import ExpandableTextLine from 'vue-expandable-text-line'
export default {
  name: 'AttachmentsView',
  components: { VimeoPlayer, ExpandableTextLine },
  props: {
    attachments: { type: [Object, Array], required: true },
    hideCaptions: Boolean,
    collapseCaption: Boolean,
    resizeBoxes: Boolean,
    maxH: null
  },

  data: () => ({
    loadedAttachments: {}
  }),

  computed: {
    singleVimeoVideo () {
      return this.attachmentsList.length === 1 && this.$refs['vimeo-player']
    },
    attachmentsList () {
      const count = Object.keys(this.attachments || {}).length
      if (!count) return []
      return Object.entries(this.attachments)
        .sort((a, b) => a.order - b.order)
        .reduce((res, [id, item]) => {
          if (item.srcSet) {
            const caption = this.hideCaptions ? '' : item.caption
            const { preview, full, original } = item.srcSet || {}
            let src
            if (item.type === 'embed/vimeo') {
              src = full
              res.push({
                id,
                type: item.type,
                vimeoId: item.name,
                url: src.url,
                dimensions: src.dimensions,
                caption: caption
              })
            } else if (item.type === 'embed/mixcloud') {
              src = full
              res.push({
                id,
                type: item.type,
                html: item.html,
                url: src.url,
                dimensions: src.dimensions,
                name: item.name,
                caption: caption
              })
            } else {
              src = full || (preview || {}).size > original.size ? preview : original
              res.push({
                id,
                type: item.type,
                url: src.url,
                dimensions: src.dimensions,
                caption: caption,
                name: item.name,
                original
              })
            }
            return res
          }
        }, [])
    }
  },

  methods: {
    setLoaded (id) {
      this.$set(this.loadedAttachments, id, true)
    },
    toggleVimeoPlay (value) {
      if (this.singleVimeoVideo) {
        this.$refs['vimeo-player'][0].togglePlay(value)
      }
    },
    toggleVimeoSound (value) {
      if (this.singleVimeoVideo) {
        this.$refs['vimeo-player'][0].toggleSound(value)
      }
    },
    boxSizeStyle (item) {
      if (!this.resizeBoxes) return null
      const { w, h } = item.dimensions
      const ratio = w / h
      let paddingTop
      if (ratio >= 1) {
        paddingTop = 100 / ratio + '%'
      } else {
        paddingTop = 100 * ratio + '%'
      }
      return { width: '100%', height: '0', paddingTop }
    }
  }
}
</script>

<style lang="scss">
  .attachments-view {
    .attachment-box {
      img {
        object-fit: contain;
        // object-position: left;
        &.loaded {
          background-color: transparent;
        }
      }
    }
    .embed-html-box {
      width: 100%;
      height: 100%;

      background: #ccc;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.2s;

        &.loaded {
          opacity: 1;
        }
      }
    }
    .caption {
      --padding: 0.5em;
      --l-height: 1.5em;
      position: absolute;
      bottom: calc((var(--l-height) + var(--padding) * 2) * -1);
      line-height: var(--l-height);
      padding: var(--padding);
      background-color: rgba(222, 216, 217, 0.91);
    }
  }
</style>
