<template>
  <div
    ref="box"
    class="vimeo-player-box relative w-full h-full">
    <vue-vimeo-player
      ref="player"
      class="video-player"
      :style="videoDimensionsStyle"
      :options="{
        background:true,
        autoplay:true,
        loop:true,
        byline:false,
        portrait:false,
        title:false,
        fullscreen:false}"
      :videoId="value.vimeoId"
      @timeupdate="$emit('timeupdate', $event)"
      @progress="$emit('progress', $event)" />
    <div class="absolute inset-0" @click="togglePlay"></div>
  </div>
</template>

<script>
import { vueVimeoPlayer } from 'vue-vimeo-player'
export default {
  name: 'VimeoPlayer',
  emits: ['paused', 'muted'],
  components: { vueVimeoPlayer },
  props: { value: { type: Object, required: true } },

  data: () => ({
  }),

  computed: {
    videoDimensionsStyle () {
      return null
    }
  },

  methods: {
    async togglePlay (value) {
      const playerRef = this.$refs.player
      if (!playerRef) return
      let paused
      if (typeof value !== 'undefined') {
        paused = value
      } else {
        paused = await playerRef.player.getPaused()
      }
      if (paused) {
        await playerRef.player.play()
        paused = await playerRef.player.getPaused()
        this.$emit('paused', paused)
      } else {
        await playerRef.player.pause()
        paused = await playerRef.player.getPaused()
        this.$emit('paused', paused)
      }
    },

    async toggleSound (value) {
      const playerRef = this.$refs.player
      if (!playerRef) return
      let muted
      if (typeof value !== 'undefined') {
        muted = value
      } else {
        muted = await playerRef.player.getMuted()
      }
      if (muted) {
        await playerRef.player.setMuted(false)
        await playerRef.player.setVolume(1)
        muted = await playerRef.player.getMuted()
        this.$emit('muted', muted)
      } else {
        await playerRef.player.setMuted(true)
        muted = await playerRef.player.getMuted()
        this.$emit('muted', muted)
      }
    }
  }
}
</script>

<style lang="scss">
.vimeo-player-box {
  position: relative;
  width: 100%;
  height: 100%;

  .video-player {
    width: 100%;
    height: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
}
.vimeo-player-box.player-aspect-ratio {
  .video-player {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
