<template>
  <div class="popover-modal modal-post post-more-popover">
    <div class="modal-shadow bg-semi-mix" @click="$emit('close')"/>
    <div class="content-wrap flex flex-col">
      <div ref="container" class="content">
        <header class="mb-lg">
          <h2 class="pr-sm">{{ title }}</h2>
          <div>{{ shortDesc }}</div>
        </header>
        <attachments-view
          :attachments="attachments"
          :max-h="containerH"
          resize-boxes/>
        <p>{{ credits }}</p>
        <div v-html="content" />
      </div>
      <footer class="flex">
        <button class="compact ml-auto" @click="$emit('close')">
          <span class="material-icons">
            close
          </span>
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import AttachmentsView from '@/views/components/AttachmentsView'
export default {
  name: 'PostMorePopover',
  components: { AttachmentsView },
  props: {
    title: String,
    shortDesc: String,
    credits: String,
    content: String,
    attachments: [Object, Array]
  },
  data: () => ({
    containerH: null
  }),
  computed: {
  },
  async mounted () {
    window.addEventListener('resize', this.setContainerH)
    await this.$nextTick()
    await this.$nextTick()
    await this.$nextTick()
    this.setContainerH()
  },
  methods: {
    setContainerH () {
      this.containerH = '100%'
      // if (!this.$refs.container) return
      // const { height } = this.$refs.container.getBoundingClientRect()
      // this.containerH = height - 48
    }
  }
}
</script>

<style lang="scss">
@import "../../styles/vars";
.popover-modal.modal-post.post-more-popover {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: var(--win-height, 100vh);

  .content-wrap {
    width: 100%;
    max-width: 65ch;
    max-height: min(calc(var(--win-height) - 1rem * 2), 600px);
    .content {
      width: 100%;
      max-width: 65ch;
      padding: $base-padding;
      height: auto;
      overflow: auto;
      border: solid rgba(0, 0, 0, 0.41);
      border-width: 1px 0;

      .attachment-box {
      }
    }
  }
  .modal-shadow {
    //background: transparent;
  }
}
</style>
