var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"attachments-view"},_vm._l((_vm.attachmentsList),function(item){return _c('div',{key:item.id,staticClass:"attachment",style:(_vm.maxH ? ("max-height: " + _vm.maxH + (typeof _vm.maxH === 'number'?'px':'')) : '')},[_c('div',{staticClass:"attachment-box relative w-full, h-full",class:{
        'bg-black': !_vm.loadedAttachments[item.id],
        'mb-lg': _vm.attachmentsList.length > 1
        }},[(item.type === 'embed/vimeo')?_c('vimeo-player',{ref:"vimeo-player",refInFor:true,staticClass:"player-aspect-ratio",style:(_vm.boxSizeStyle(item)),attrs:{"value":item},on:{"paused":function($event){return _vm.$emit('paused', $event)},"muted":function($event){return _vm.$emit('muted', $event)},"timeupdate":function($event){return _vm.$emit('timeupdate', $event)},"progress":function($event){return _vm.$emit('progress', $event)}}}):(item.type === 'embed/mixcloud')?_c('div',{domProps:{"innerHTML":_vm._s(item.html)}}):(item.type === 'embed/html')?_c('div',{staticClass:"embed-html-box",style:(_vm.boxSizeStyle(item))},[(!_vm.loadedAttachments[item.id])?_c('div',{staticClass:"absolute flex inset-0 items-center justify-center"},[_c('span',[_vm._v("Loading...")])]):_vm._e(),_c('iframe',{class:{loaded: _vm.loadedAttachments[item.id]},attrs:{"src":item.url,"allowfullscreen":""},on:{"load":function($event){return _vm.setLoaded(item.id)}}})]):_c('img',{class:{
          outline: item.type === 'application/pdf',
          loaded: _vm.loadedAttachments[item.id],
          'bg-gray-400': !_vm.loadedAttachments[item.id]
        },attrs:{"src":item.url,"alt":item.caption,"width":(item.dimensions || {}).w,"height":(item.dimensions || {}).h},on:{"load":function($event){return _vm.setLoaded(item.id)}}}),(item.type === 'application/pdf')?_c('a',{staticClass:"button compact absolute top-0 right-0 mt-sm mr-sm flex flex-col items-center justify-center hover:no-underline focus:no-underline bg-milk",attrs:{"href":item.original.url,"target":"_blank","download":""}},[_c('i',{staticClass:"material-icons"},[_vm._v("get_app")]),_c('span',{staticClass:"text-xs"},[_vm._v("PDF")])]):_vm._e(),(item.caption && _vm.collapseCaption)?_c('expandable-text-line',{staticClass:"caption text-xs"},[_vm._v(" "+_vm._s(item.caption)+" ")]):_vm._e()],1),(item.caption && !_vm.collapseCaption)?_c('div',{staticClass:"text-xs pt-sm -mt-lg mb-lg"},[_vm._v(" "+_vm._s(item.caption)+" ")]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }