var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"box",staticClass:"vimeo-player-box relative w-full h-full"},[_c('vue-vimeo-player',{ref:"player",staticClass:"video-player",style:(_vm.videoDimensionsStyle),attrs:{"options":{
      background:true,
      autoplay:true,
      loop:true,
      byline:false,
      portrait:false,
      title:false,
      fullscreen:false},"videoId":_vm.value.vimeoId},on:{"timeupdate":function($event){return _vm.$emit('timeupdate', $event)},"progress":function($event){return _vm.$emit('progress', $event)}}}),_c('div',{staticClass:"absolute inset-0",on:{"click":_vm.togglePlay}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }