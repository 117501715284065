<template>
  <popover-modal-post ref="modal" :style="boxStyle" :class="modalFitClass">
    <transition>
      <post-view
        ref="post-view"
        @set-post="setPost"
        @allow-edit="setAllowEdit"
        @download-link="setDownloadLink"
        @allow-forward="setAllowForward"
        @allow-backward="setAllowBackward"
        @update-box="updateBoxStyle"
        @close="close"/>
    </transition>
    <template v-slot:header>
      <edit-button-badge
        class="overlay"
        :allow-edit="allowEdit"
        :download-link="downloadLink"
        @open-editor="openEditor">
      </edit-button-badge>
    </template>
  </popover-modal-post>
</template>

<script>
import PopoverModalPost from './components/UI/PopoverModalPost'
import PostView from './PostView'
import EditButtonBadge from './components/EditButtonBadge'

export default {
  name: 'PostPopover',
  components: { EditButtonBadge, PopoverModalPost, PostView },
  data: () => ({
    post: null,
    allowEdit: false,
    allowForward: true,
    allowBackward: true,
    downloadLink: '',
    boxStyle: {},
    modalFitClass: null
  }),
  computed: {},
  methods: {
    setPost (post) {
      this.post = post || {}
    },

    close () {
      const func = (this.$refs.modal || {}).close
      if (typeof func === 'function') {
        func()
      }
    },

    async updateBoxStyle () {
      await this.$nextTick()
      const box = (this.$refs['post-view'] || {}).$el
      const modal = (this.$refs.modal || {}).$el
      if (!box) return {}
      this.modalFitClass = document.documentElement.style.getPropertyValue('--modal-box-fit-type')
      await this.$nextTick()
      const { width, height } = modal.getBoundingClientRect()
      this.boxStyle = {
        left: Math.round((window.innerWidth - width) / 2) + 'px',
        top: Math.round((window.innerHeight - height) / 2) + 'px'
      }
    },

    setAllowEdit (val) {
      this.allowEdit = !!val
    },

    setDownloadLink (val) {
      this.downloadLink = val
    },

    openEditor () {
      const func = (this.$refs['post-view'] || {}).openEditor
      if (typeof func === 'function') {
        func()
      }
    },

    goForward () {
      const func = (this.$refs['post-view'] || {}).goForward
      if (typeof func === 'function') {
        func()
      }
    },

    goBackward () {
      const func = (this.$refs['post-view'] || {}).goBackward
      if (typeof func === 'function') {
        func()
      }
    },

    setAllowForward (val) {
      this.allowForward = val
    },
    setAllowBackward (val) {
      this.allowBackward = val
    }
  }
}
</script>
<style lang="scss" >
@import "../styles/vars";
.popover-modal.modal-post {
  --footer-height: #{$base-size * 0.75};
  --max-box-width: 800px;
  --max-box-height: 450px;
  --box-margins: #{$base-padding};

  //font-family: #{$font-family-sans-serif};
  background: transparent;
  //padding-bottom: var(--footer-height);
  height: 100%;
  // max-height: var(--max-box-height);

  &.fit-in-width {
    --width-formula: min(calc(100vw - #{$base-padding} * 2), var(--max-box-width));
    width: calc(var(--width-formula));
    height: calc(var(--width-formula) / var(--modal-box-ratio, 1) + var(--footer-height));
  }

  &.fit-in-height {
    --height-formula: min(calc(100vh - #{$base-padding} * 3 - var(--footer-height)), var(--max-box-height));
    height: calc(var(--height-formula) + var(--footer-height));
    width: calc(var(--height-formula) * var(--modal-box-ratio, 1));
  }
}
</style>
