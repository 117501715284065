<template>
  <div ref="box" class="post-view page relative" :class="boxSizeClass">
    <attachments-view
      ref="first-attachment"
      :attachments="firstAttachment ? [firstAttachment] : []"
      hide-captions
      class="first-attachment"
      @paused="paused = $event"
      @muted="muted = $event"
      @timeupdate="timeupdate = $event"
      @progress="progress = $event" />
    <footer class="absolute bottom-0 flex items-end w-full">
      <expandable-text-line class="bg-semi-mix min-h-2/3base leading-tight py-2 pr-1">
        <span class="font-bold">{{ title }} </span>
        <span class="">{{ shortDesc }}</span>
      </expandable-text-line>
      <div class="bg-semi-mix flex min-h-2/3base items-end">
        <a
          class="cursor-pointer leading-tight p-2 text-aba-blue"
          @click.prevent="openMore">
          more
        </a>
        <div v-if="firstAttachmentIsVideo" class="video-controls flex items-center">
          <div class="font-mono text-xs whitespace-no-wrap opacity-50 ml-base">
            {{videoPlaybackCountDown}}
          </div>
          <button class="compact" @click="toggleVimeoPlay">
            <span class="material-icons">
              {{ this.paused ? 'play_arrow' : 'pause' }}
            </span>
          </button>
          <button class="compact" @click="toggleVimeoSound">
            <span class="material-icons">
              {{ this.muted ? 'volume_off' : 'volume_up' }}
            </span>
          </button>
        </div>
        <button class="compact" @click="$emit('close')">
          <span class="material-icons">close</span>
        </button>
      </div>
    </footer>
    <transition>
      <post-more-popover
        ref="more-popover"
        v-if="showMore"
        :title="title"
        :short-desc="shortDesc"
        :content="content"
        :credits="credits"
        :attachments="otherAttachments"
        @close="closeMore"
        class="more-popup" />
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { db } from '@/lib/firebase'
import ExpandableTextLine from 'vue-expandable-text-line'
import AttachmentsView from '@/views/components/AttachmentsView'
import DOMPurify from 'dompurify'
import PostMorePopover from '@/views/components/PostMorePopover'

export default {
  name: 'PostView',
  components: { PostMorePopover, AttachmentsView, ExpandableTextLine },
  props: {
    value: { type: Object, default: null }
  },
  data: () => ({
    unsubscribe: null,
    post: null,
    winRatio: window.innerWidth / window.innerHeight,
    image: null,
    imgLoadedClass: null,
    boxSizeClass: '',
    boxMaxH: '450px',
    boxMaxW: '800px',
    footerHeight: '48px',
    showMore: false,
    paused: false,
    muted: true,
    progress: 0,
    timeupdate: null
  }),

  computed: {
    ...mapState(['user', 'feed']),
    allowEdit () {
      return !!this.user && (this.user.role === 'admin' || this.user.role === 'editor')
    },

    postId () {
      return this.$route.params.id
    },

    shortDesc () {
      const tech = (this.post || {}).technique || ''
      const year = (this.post || {}).year || ''
      return `${tech}${tech && year ? ' | ' : ''}${year}`
    },

    title () {
      return (this.post || {}).title || ''
    },
    credits () {
      return (this.post || {}).credits || ''
    },

    content () {
      if ((this.post || {}).content) {
        return DOMPurify.sanitize(this.post.content)
      }
      return ''
    },

    orderedAttachments () {
      return (Object.values((this.post || {}).attachments || {}) || []).sort((a, b) => a.order - b.order)
    },

    otherAttachments () {
      const [, ...rest] = this.orderedAttachments
      return rest
    },

    firstAttachment () {
      return this.orderedAttachments[0] || null
    },

    firstAttachmentIsVideo () {
      return (this.firstAttachment || {}).type === 'embed/vimeo'
    },

    firstDimensions () {
      const { full, original } = (this.firstAttachment || {}).srcSet || {}
      return (full || {}).dimensions || (original || {}).dimensions
    },

    orientation () {
      if (!this.firstDimensions) return null
      const { w, h } = this.firstDimensions
      return w > h ? 'landscape' : 'portrait'
    },

    posts () {
      return Object.values(this.feed || {}).sort((a, b) => b.date - a.date)
    },

    curIndex () {
      return (this.posts || []).findIndex(post => post.id === this.postId)
    },

    videoPlaybackCountDown () {
      if (!this.timeupdate) return ''
      const toTimeCode = timeSec => {
        const sec = parseInt(Math.abs(timeSec), 10) // don't forget the second param
        let hours = Math.floor(sec / 3600)
        let minutes = Math.floor((sec - (hours * 3600)) / 60)
        let seconds = sec - (hours * 3600) - (minutes * 60)

        if (hours > 0 && hours < 10) { hours = '0' + hours }
        if (minutes < 10) { minutes = '0' + minutes }
        if (seconds < 10) { seconds = '0' + seconds }
        return (hours > 0 ? `${hours}:` : '') + minutes + ':' + seconds
      }
      const remained = this.timeupdate.seconds - this.timeupdate.duration
      return (remained < 0 ? '-' : '') + toTimeCode(remained)
    }
  },

  created () {
    this.subscribePost()
    window.addEventListener('resize', () => {
      this.winRatio = window.innerWidth / window.innerHeight
    })
  },

  mounted () {
    this.emitAllowEdit()
    this.emitAllowNavigation()
  },

  watch: {
    $route () {
      this.imgLoadedClass = null
      this.unsubscribePost()
      this.subscribePost()
      this.emitAllowNavigation()
    },

    firstDimensions () {
      this.setBoxSizeCss()
      this.$emit('update-box')
    },
    winRatio () {
      this.setBoxSizeCss()
      this.$emit('update-box')
    },

    allowEdit () {
      this.emitAllowEdit()
    },

    post () {
      this.$emit('set-post', this.post)
    }
  },

  beforeDestroy () {
    this.unsubscribePost()
  },

  methods: {
    ...mapActions(['showEditor']),
    openEditor () {
      if (this.post) this.showEditor({ value: this.post })
    },
    subscribePost () {
      if (this.value) {
        this.post = this.value
        return
      }
      if ((this.feed || {})[this.postId]) {
        this.post = this.feed[this.postId]
        return
      }
      if (!this.postId) return this.unsubscribePost()
      this.unsubscribe = db.collection('posts')
        .doc(this.postId)
        .onSnapshot(doc => {
          this.post = { ...doc.data(), id: doc.id }
        }, err => {
          console.error('Event subscribePost:', err)
        })
    },

    async setBoxSizeCss () {
      /** @type HTMLElement */
      const box = this.$refs.box
      if (!box) return
      if (!this.firstDimensions) return

      const root = document.documentElement
      if (!this.rootCompStyle) this.rootCompStyle = getComputedStyle(root)
      if (!this.boxCompStyle) this.boxCompStyle = getComputedStyle(box)

      const rootFontSize = parseFloat(this.rootCompStyle.fontSize)
      const footerH = (parseFloat(this.boxCompStyle.getPropertyValue('--footer-height')) || 1) * rootFontSize
      const maxH = (parseFloat(this.boxCompStyle.getPropertyValue('--max-box-height')) || 1)
      const boxMarg = (parseFloat(this.boxCompStyle.getPropertyValue('--box-margins')) || 1) * rootFontSize

      const { w, h } = this.firstDimensions
      const attachmentRatio = w / h

      const testH = Math.min(window.innerHeight - boxMarg * 2 - footerH, maxH)

      const testW = testH * attachmentRatio
      const testHWithFooter = testH + footerH
      const boxWithFooterRatio = testW / testHWithFooter
      if (boxWithFooterRatio >= this.winRatio) {
        this.boxSizeClass = 'fit-in-width'
      } else {
        this.boxSizeClass = 'fit-in-height'
      }
      box.style.setProperty('--box-ratio', `${attachmentRatio}`)
      root.style.setProperty('--modal-box-ratio', `${attachmentRatio}`)
      root.style.setProperty('--modal-box-fit-type', `${this.boxSizeClass}`)
    },

    openMore () {
      this.showMore = !this.showMore
      this.pausedBeforeMore = this.paused
      if (this.paused) return
      this.toggleVimeoPlay(false)
    },

    closeMore () {
      this.showMore = false
      if (this.pausedBeforeMore === false) {
        this.toggleVimeoPlay(true)
      }
    },

    unsubscribePost () {
      if (typeof this.unsubscribe === 'function') this.unsubscribe()
      this.post = null
    },

    toggleVimeoPlay (value) {
      const state = typeof value === 'boolean' ? value : undefined
      if (this.$refs['first-attachment']) {
        this.$refs['first-attachment'].toggleVimeoPlay(state)
      }
    },
    toggleVimeoSound (value) {
      const state = typeof value === 'boolean' ? value : undefined
      if (this.$refs['first-attachment']) {
        this.$refs['first-attachment'].toggleVimeoSound(state)
      }
    },

    goBackward () {
      this.goNext(-1)
    },

    goForward () {
      this.goNext(1)
    },

    goNext (step) {
      const nextIndex = this.curIndex + step
      if (this.curIndex > -1 && nextIndex > -1 && nextIndex < this.posts.length) {
        const nextPostId = this.posts[nextIndex].id
        const nextRout = { name: 'post', params: { postId: nextPostId } }
        this.emitAllowNavigation()
        this.$router.push(nextRout)
      } else {
        if (step > 0) {
          this.$emit('allow-forward', false)
        } else {
          this.$emit('allow-backward', false)
        }
      }
    },

    emitAllowEdit () {
      this.$emit('allow-edit', this.allowEdit)
    },

    emitDownloadLink () {
      this.$emit('download-link', this.downloadLink)
    },

    emitAllowNavigation () {
      this.$emit('allow-forward', this.curIndex > -1 && this.curIndex < this.posts.length - 1)
      this.$emit('allow-backward', this.curIndex > 0)
    }
  }
}
</script>

<style lang="scss">
@import "../styles/vars";
  .post-view.page {
    //--footer-height: #{$base-size * 0.75};
    //--max-box-width: 800px;
    //--max-box-height: 450px;
    //--box-margins: #{$base-padding};

    //font-family: #{$font-family-sans-serif};
    background: transparent;
    //padding-bottom: var(--footer-height);
    height: 100%;
    width: 100%;
    //max-height: var(--max-box-height);
    //
    // &.fit-in-width {
    //  --width-formula: min(calc(100vw - #{$base-padding} * 2), var(--max-box-width));
    //  width: calc(var(--width-formula));
    //  height: calc(var(--width-formula) / var(--box-ratio, 1) + var(--footer-height));
    // }
    // //
    // &.fit-in-height {
    //  --height-formula: min(calc(100vh - #{$base-padding} * 2 - var(--footer-height)), var(--max-box-height));
    //  height: calc(var(--height-formula) + var(--footer-height));
    //  width: calc(var(--height-formula) * var(--box-ratio, 1));
    // }
  }
  .first-attachment.attachments-view {
    height: calc(100% - var(--footer-height));

    .attachment {
      height: 100%;

      .attachment-box {
        width: 100%;
        height: 100%;
        background: transparent;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          opacity: 0;
          transition: opacity 0.5s;
          &.loaded {
            opacity: 1;
          }
        }
      }
    }
  }
  .event {
    .list-string {
      & >*:not(:last-child) {
        &:after {
          content: ", ";
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
  .more-popup{
    position: fixed;
    //width: 100vw;
    //height: auto;
    //max-width: calc(100vw - 20px);
    //max-height: calc(var(--win-height) - 20px);
    //top: 0;
    //left: 0;
    overflow: auto;
    z-index: 2000000;
  }
</style>
